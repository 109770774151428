import $ from "jquery";
import Cookies from "js-cookie";
import React, { Component, Fragment } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {
  getAllNavigation,
  getHeaderNavigation,
  getSetting,
  getTopNavigation,
  setLayout,
  setPath,
  setPathLayout,
  setSearchKey,
} from "../../../actions/Utils";
import Logo from "../../../img/logo.png";
import LangData from "../LangData";
import LangSelector from "./LangSelector";
import MultiMenus from "./Multimenu";
import MultiMenusMobile from "./MultimenuMobile";

// import '../../../Styles/App.css'
// import '../../../Styles/Header.css'

class Commonheader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: "",
      header: {},
      top_menu: [],
      header_menu: [],
      megamenu_class: style.display_none,
      search: "",
      accept_cookies: false,
      setting: {},
      menu_open: false,
    };
    this.megaMenuShow = this.megaMenuShow.bind(this);
    this.megaMenuHide = this.megaMenuHide.bind(this);
  }

  componentDidMount = () => {
    this.props.getSetting().then((res) => {
      this.setState({
        setting: res,
      });
    });
    this.props
      .getHeaderNavigation({ navigation_name: "header" })
      .then((res) => {
        this.setState({
          header_menu: res,
        });

        // this.menuLoad();
      });
    var accept_cookiess = Cookies.get("accept_cookies");
    if (accept_cookiess && accept_cookiess == true) {
      this.setState({ accept_cookies: true });
    }

    // var login_token = Cookies.get('login_token');
    // if (!login_token || login_token !== localStorage.getItem('token')) {
    //     localStorage.removeItem("token");
    //     localStorage.removeItem("member_id");
    //     localStorage.removeItem("member_pass");
    // }
  };

  handleMenu = (status) => {
    this.setState({
      menu_open: status,
    });
  };

  menuLoad_header = () => {
    const thiss = this;
    setTimeout(() => {
      $(".menu > ul > li:has( > ul)").addClass("menu-dropdown-icon");
      $(".menu > ul > li > ul:not(:has(ul))").addClass("normal-sub");

      //mobarak
      // $('.menu > ul > li > ul li:has( > ul)').addClass('menu-dropdown-icon');
      // $('.menu > ul > li > ul > li > ul:not(:has(ul))').addClass('normal-sub');

      // $(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\">&nbsp;</a>");

      $(".menu > ul > li").hover(function (e) {
        if ($(window).width() > 943) {
          $(this).children("ul").stop(true, false).fadeToggle(150);
          e.preventDefault();
        }
      });

      //mobarak now
      // $(".third-label-mobarak").hover(function (e) {
      //     if ($(window).width() > 943) {
      //         $(this).children("ul").fadeToggle(150);
      //         e.preventDefault();
      //     }
      // });

      $(".menu > ul > li").click(function () {
        if ($(window).width() <= 943) {
          $(this).children("ul").fadeToggle(150);
          if (!$(this).children("ul").length) {
            $(".menu > ul").toggleClass("show-on-mobile");
            $(".menu-mobile").toggleClass("menu-mobile-close");
          }
        }
      });

      // $(".menu > ul > li > ul > li").click(function () {
      //     if ($(window).width() <= 943) {
      //         if(!$(this).children("ul").length){
      //             $(".menu > ul").toggleClass('show-on-mobile');
      //         }

      //     }
      // });

      $(".menu-mobile").click(function (e) {
        $(".menu > ul").toggleClass("show-on-mobile");
        $(this).toggleClass("menu-mobile-close");

        e.preventDefault();
      });
    }, 1000);

    const lang = Cookies.get("lang") ? Cookies.get("lang") : "bn";
    if (lang == "bn") {
      if (!$("body").hasClass("content_bangla")) {
        $("body").addClass("content_bangla");
      }
    } else {
      if ($("body").hasClass("content_bangla")) {
        $("body").removeClass("content_bangla");
      }
    }
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { history, match } = this.props;

    const { search } = this.state;
    if (search.length) {
      this.setState({ search: "" });
      history.push(`${process.env.PUBLIC_URL}${match.url}search/${search}`);
      $("div.mk-fullscreen-search-overlay").removeClass(
        "mk-fullscreen-search-overlay-show"
      );
    }
  };
  acceptCookies = (e) => {
    e.preventDefault();
    Cookies.set("accept_cookies", 1);
    this.setState({ accept_cookies: true });
  };
  static getDerivedStateFromProps(props, state) {
    return {
      header: props.headers,
      lang: props.lang,
    };
  }

  megaMenuShow = () => {
    // console.log("Hello Mobarak Yes hsow")
    this.setState({
      megamenu_class: style.display_block,
    });
  };
  megaMenuHide = () => {
    this.setState({
      megamenu_class: style.display_none,
    });
  };

  handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("member_id");
    localStorage.removeItem("member_pass");
    this.props.history.push(`${process.env.PUBLIC_URL}/`);
  };

  handleThirdLabel = (event, targetid) => {
    if ($(window).width() <= 943) {
      if ($("#" + targetid).children("ul").length) {
        setTimeout(() => {
          $("#" + targetid)
            .closest("ul")
            .css("display", "block");
        }, 600);

        $("#" + targetid)
          .find("ul")
          .css("display", "block");
      } else {
        // console.log("show-on-mobile handleThirdLabel")
        setTimeout(() => {
          $(".menu-mobile").toggleClass("menu-mobile-close");
          $(".menu > ul").toggleClass("show-on-mobile");
        }, 300);
      }
    }
  };
  handleThirdList = () => {
    if ($(window).width() <= 943) {
      setTimeout(() => {
        $(".menu-mobile").toggleClass("menu-mobile-close");
        $(".menu > ul").toggleClass("show-on-mobile");
      }, 300);
    }
  };
  handleThirdLableMouseOut = (event, targetid) => {
    if ($(window).width() <= 943) {
      if ($("#" + targetid).children("ul").length) {
        setTimeout(() => {
          $("#" + targetid)
            .find("ul")
            .css("display", "none");
        }, 300);
      }
    }
  };
  linkadd = (id) => {
    //alert(id);
  };

  render() {
    const top_menu = this.props.headers.top_menu;
    const header_menu = this.props.headers.header_menu;
    //console.log("data setting state =", this.state);
    const { megamenu_class, setting, menu_open } = this.state;
    // const lang = Cookies.get('lang')?Cookies.get('lang'):'bn';
    const lang = this.props.lang;
    var search_placeholder = lang == "bn" ? "অনুসন্ধান" : "look out";

    return (
      <Fragment>
        {/* <!-- Cookies Start --> */}
        {/* {
                    !this.state.accept_cookies ?
                        <div className="alert alert-warning alert-dismissible fade show cookies" role="alert">

                            <div className="container">
                                <p>
                                    This site uses cookies and other tracking technologies to assist with navigation and your ability to provide feedback, analyse your use of our products and services, assist with our promotional and marketing efforts, and provide content from third parties.

                                </p>

                                <a href="" onClick={this.acceptCookies.bind(this)} className="btn btn-primary"><i className="fa fa-check"></i> Accept Cookies</a>


                            </div>

                        </div>
                        :

                        ''
                } */}

        {/*  <!-- Cookies End --> */}

        <header>
          <section className="topbar-section">
            <div className="container">
              <div className="topbar">
                <div className="topbar-info-left float-left">
                  <p>
                    <i className="fa fa-phone-alt"></i>
                    {lang === "en" && <Fragment>{setting.phone}</Fragment>}
                    {lang === "bn" && <Fragment>{setting.bn_phone}</Fragment>}
                  </p>
                  <p>
                    <i className="fa fa-envelope"></i> {setting.email}
                  </p>
                </div>
                <div className="topbar-info-right float-right">
                  <p>
                    <Link to={{ pathname: "/signup" }}>
                      <LangData keyname="sign_up" />
                    </Link>{" "}
                    /{" "}
                    <Link to={{ pathname: "/login" }}>
                      <LangData keyname="login" />
                    </Link>
                  </p>
                  <LangSelector />
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="d-flex top-middle">
                <div className="logo mr-auto">
                  <Link to={{ pathname: `/` }}>
                    <img src={Logo} alt="Logo" />
                  </Link>
                </div>
                <div className="payment-calculator d-flex">
                  {/* <div className="payment">
                                        
                                        <Link to={{pathname:`/paydirect-step-1`}} >
                                            <LangData keyname="payment" />  <img src={require('../../../img/icon/payment.svg').default} />
                                        </Link>

                                    </div> */}
                  <div className="calculator">
                    <Link to={{ pathname: `/return-calculator` }}>
                      <LangData keyname="rcalculator" />{" "}
                      <img
                        src={
                          require("../../../img/icon/calculator.svg").default
                        }
                      />
                    </Link>
                  </div>
                  <div className="calculator">
                    <Link to={{ pathname: `/premium-calculator` }}>
                      <LangData keyname="calculator" />{" "}
                      <img
                        src={
                          require("../../../img/icon/calculator.svg").default
                        }
                      />
                    </Link>
                  </div>
                </div>
                <div className="search-box">
                  <form action="#">
                    <input
                      type="text"
                      name="search"
                      value={this.state.search}
                      onChange={this.handleChange}
                      placeholder={search_placeholder}
                    />
                    <button
                      className="search-btn"
                      onClick={this.handleSubmit}
                      type="submit"
                    ></button>
                  </form>
                </div>
              </div>
            </div>
          </section>
          <section className="sticky-menu">
            <div className="container">
              <div className="main-menu">
                <div className="menu-container">
                  <MobileView>
                    {menu_open && (
                      <a
                        href="javscript:void(0)"
                        className="menu-mobile-close"
                        onClick={() => this.handleMenu(false)}
                      >
                        &nbsp;
                      </a>
                    )}
                    {!menu_open && (
                      <a
                        href="javscript:void(0)"
                        className="menu-mobile"
                        onClick={() => this.handleMenu(true)}
                      >
                        &nbsp;
                      </a>
                    )}
                  </MobileView>

                  <MobileView>
                    <div className="menu">
                      <div className="mobile-logo">
                        <Link to={{ pathname: "/" }}>
                          <img src={Logo} alt="Logo" />
                        </Link>
                      </div>
                      <MultiMenusMobile
                        menus={header_menu}
                        lang={lang}
                        menu_open={menu_open}
                        handleMenu={(status) => this.handleMenu(status)}
                      />
                    </div>
                  </MobileView>
                  <BrowserView>
                    <div className="menu">
                      <MultiMenus menus={header_menu} lang={lang} />
                    </div>
                  </BrowserView>
                </div>
              </div>
            </div>
          </section>
        </header>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  headers: state.header,
  setting: state.utils.setting,
  lang: state.utils.lang,
});

const mapDispatchToProps = (dispatch) => ({
  setPath: (requestData) => {
    dispatch(setPath(requestData));
  },
  setLayout: (requestData) => {
    dispatch(setLayout(requestData));
  },
  setPathLayout: (requestData) => {
    dispatch(setPathLayout(requestData));
  },
  setSearchKey: (requestData) => {
    dispatch(setSearchKey(requestData));
  },
  getAllNavigation: (requestData) => dispatch(getAllNavigation(requestData)),
  getTopNavigation: (requestData) => dispatch(getTopNavigation(requestData)),
  getHeaderNavigation: (requestData) =>
    dispatch(getHeaderNavigation(requestData)),
  getSetting: (requestData) => dispatch(getSetting(requestData)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Commonheader)
);

const style = {
  display_none: {
    display: "none",
  },
  display_block: {
    display: "block",
  },
};
